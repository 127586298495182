<template>
  <div>
    <div
      v-if="isBrand"
      class="row m-0 mb-3"
    >
      <div class="col-lg-6 pl-0 col-sm-12 pr-sm-0 pr-lg-1">
        <div class="my-retailer-banner-1 my-retailer-banner">
          <div class="banner-content">
            <div class="title">
              Your Direct Retailers
            </div>
            <div class="sub-title">
              Access your exclusive partners in this tab
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 pr-0 col-sm-12 pl-sm-0 pl-lg-1 mt-sm-1 mt-lg-0">
        <div class="my-retailer-banner-2 my-retailer-banner">
          <div class="banner-content">
            <div class="title">
              Invite your existing retailers
            </div>
            <div class="sub-title">
              to join the platform for free
            </div>
            <div class="mt-10-px">
              <b-button
                v-b-modal.add_a_user
                variant="custom-primary"
                class="d-flex align-items-center"
              >
                <featherIcon
                  icon="PlusIcon"
                  size="18"
                />
                <span class="ml-5-px"> Add a Retailer </span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex browse-nav-bar">
      <div
        class="browse-nav-item"
        :class="{ 'active': CONNECTION_TABS.DIRECT === selectedTab }"
        @click="onLoadTab(CONNECTION_TABS.DIRECT)"
      >
        DIRECT
      </div>
      <div
        class="browse-nav-item"
        :class="{ 'active': CONNECTION_TABS.MARKETPLACE === selectedTab }"
        @click="onLoadTab(CONNECTION_TABS.MARKETPLACE)"
      >
        MARKETPLACE
      </div>
    </div>
    <div class="row mb-1">
      <div
        v-if="tableLength"
        class="position-relative col-md-4"
      >
        <b-form-input
          v-model="search"
          :placeholder="getTextForPlaceholder"
          class="search-product"
        />
        <feather-icon
          icon="SearchIcon"
          size="18"
          class="wallet-search-icon"
        />
      </div>
      <div
        v-if="(isEntityAdmin || isSalesManager) && isBrand && !loading && tableLength && selectedTab === CONNECTION_TABS.DIRECT"
        class="col-lg-3 col-md-4 pl-md-0 mt-sm-1 mt-md-0"
      >
        <reps-list-dropdown
          :options="salesReps"
          :show-self-option="true"
          @on-select-options="onFilterBySalesRep"
        />
      </div>
      <div
        v-if="isEntityAdmin && isBrand && !loading && tableLength && selectedTab === CONNECTION_TABS.DIRECT"
        class="col-lg-3 col-md-4 pl-md-0 mt-sm-1 mt-md-0"
      >
        <reps-list-dropdown
          :options="salesManagers"
          placeholder="Sales manager"
          :show-self-option="true"
          @on-select-options="onFilterBySalesManager"
        />
      </div>
      <div
        v-if="isShowImportExport"
        :class="hasDirectUsers ? 'col-lg-2 col-md-12 mt-sm-1 mt-lg-0': 'col-lg-12 col-md-12'"
        class="d-flex flex-row justify-content-end"
      >
        <b-button
          v-b-modal.import_direct_retailers
          variant="outline-secondary-black"
        >
          <import-icon class="mr-5-px" />
          Import
        </b-button>
      </div>

    </div>
    <div
      v-if="!loading && !(tableItems && tableItems.length)"
      class="items-empty-message-container"
    >
      <div class="mb-1">
        <send-icon />
      </div>
      <b-card-text class="list-empty-text-color">
        There are no connections yet.
      </b-card-text>
    </div>
    <b-card
      v-else
      class="app-connection-request-list"
    >
      <div
        v-if="loading"
        class="text-center p-5"
      >
        <b-spinner />
      </div>
      <div
        v-else
        class="mb-2"
      >
        <b-table
          :fields="tableFields"
          :items="tableItems"
          class="connection-table"
          show-empty
          :current-page="currentPage"
          :per-page="perPage"
          :filter="search"
          :filter-included-fields="filterIncludedFields"
          responsive
          empty-text="No data found."
        >
          <!-- Column: SR Name -->
          <template #cell(salesRepName)="data">
            <span class="d-block text-nowrap">
              {{ data.item.isCreatedBySelf ? profile.entityName : data.item.salesRepName }}
            </span>
          </template>

          <template #cell(firstOrderDate)="data">
            <div
              v-if="data.item.firstOrderDate"
              class="d-flex flex-nowrap"
            >
              <feather-icon
                icon="ClockIcon"
                size="18"
                class="mr-50 text-info"
              />
              {{ data.item.firstOrderDate }}
            </div>
          </template>
          <template #cell(nickName)="data">
            <add-nick-name
              :data="data"
              @load-connections="getData()"
            />
          </template>
          <template #cell(lastOrderDate)="data">
            <div
              v-if="data.item.lastOrderDate"
              class="d-flex flex-nowrap"
            >
              <feather-icon
                icon="ClockIcon"
                size="18"
                class="mr-50 text-info"
              />
              {{ data.item.lastOrderDate }}
            </div>
          </template>
          <template #cell(socialLinks)="data">
            <div class="d-flex flex-nowrap mt-2">
              <b-link
                v-if="data.item.socialLinks.instagramLink"
                class="mr-2"
                :href="data.item.socialLinks.instagramLink || '#'"
                target="_blank"
              >
                <instagram-icon />
              </b-link>
              <b-link
                v-if="data.item.socialLinks.facebookLink"
                class="mr-2"
                :href="data.item.socialLinks.facebookLink || '#'"
                target="_blank"
              >
                <facebook-icon />
              </b-link>
              <b-link
                v-if="data.item.socialLinks.linkedinLink"
                class="mr-2"
                :href="data.item.socialLinks.linkedinLink || '#'"
                target="_blank"
              >
                <linked-in-icon />
              </b-link>
              <b-link
                v-if="data.item.socialLinks.twitterLink"
                class="mr-2"
                :href="data.item.socialLinks.twitterLink || '#'"
                target="_blank"
              >
                <twitter-icon />
              </b-link>
              <b-link
                v-if="data.item.socialLinks.websiteLink"
                class="mr-2"
                :href="data.item.socialLinks.websiteLink || '#'"
                target="_blank"
              >
                <feather-icon
                  icon="GlobeIcon"
                  size="20"
                />
              </b-link>
            </div>
          </template>
          <template #head(actions)>
            <div class="elements-center">
              actions
            </div>
          </template>
          <template #head(firstName)>
            {{ getRoleText }} Name
          </template>
          <template #head(entityName)>
            {{ isBrand ? 'Store Name' : 'Brand' }}
          </template>
          <template #cell(actions)="data">
            <div class="elements-center">
              <span
                class="cursor-pointer"
                @click="editUser(data.item)"
              >
                <pencil-icon-2 />
              </span>
            </div>
          </template>
          <template #cell(discount)="data">
            {{ getDicountWithPercent(data.item.discount) }}%
          </template>
          <template #cell(paymentTerms)="data">
            <div class="payment-term-block">
              {{ data.item.paymentTerms }}
            </div>
          </template>
        </b-table>
        <b-modal
          id="connection-request"
          ref="connnectionRequest"
          class="custom-modal"
          size="lg"
          hide-footer
          hide-header
          hide-header-close
          no-close-on-backdrop
          centered
        >
          <connection-request
            :brand-id="brandId"
            :retailer-id="retailerId"
            :request-id="requestId"
            :status="status"
            :request-message="requestMessage"
            @close-modal="closeModal"
            @update-request="getData"
          />
        </b-modal>
      </div>
      <div class="mx-2 mb-2 mt-auto">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="tableDataCount"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <add-user-modal @load-connections="getData()" />
    <edit-user-modal
      v-if="modalReloadKey"
      :key="'edit_user_modal'+modalReloadKey"
      :user-details="userData"
      :load-connections="getData"
    />

    <b-modal
      id="import_direct_retailers"
      size="lg"
      modal-class="modal-primary custom-model"
      centered
      hide-header-close
      hide-header
      hide-footer
    >
      <div>
        <div class="pb-50 border-bottom">
          <span class="custom-modal-heading">Import direct retailers</span>
          <feather-icon
            class="float-right close-x-icon"
            icon="XIcon"
            size="24"
            @click="closeImportDirectRetailersModal"
          />
        </div>
        <div class="my-1">
          <span>Export direct retailers</span>
          <b-button
            variant="primary"
            class="ml-1"
            :disabled="isExporting"
            @click="exportDirectUsers"
          >
            <div class="d-flex align-items-center">
              <feather-icon
                icon="DownloadIcon"
                class="mr-1"
                size="20"
              />
              {{ isExporting ? 'Downloading' : 'Download' }}
            </div>
          </b-button>
        </div>
        <label
          v-if="!directRetailersImportFile"
          for="uploadCatalog"
          class="fileDownload elements-center"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="50"
            color="skyblue"
          />
          <h4 class="font-weight-bolder"><b>Drag & Drop</b></h4>
          <h6>or click to select files from your system</h6>
        </label>
        <div
          v-if="directRetailersImportFile"
          class="d-flex file-droped mt-50"
        >
          <b-img
            :src="xlsxIcon"
            class="mx-1"
            alt="ErrorIcon"
          />
          <span class="catalog-label name">Name: {{ directRetailersImportFile.name }} </span>
          <span
            class="catalog-label size"
          >Size: {{ (formatNumber(directRetailersImportFile.size / 1024), 2) }} KB</span>
          <div
            v-if="directRetailersImportFile"
            class="delete-file d-flex align-items-center justify-content-center"
            @click="directRetailersImportFile = null"
          >
            <feather-icon
              icon="TrashIcon"
              size="24"
              fill="white"
              color="white"
            />
          </div>
        </div>
        <b-form-file
          id="uploadCatalog"
          v-model="directRetailersImportFile"
          name="uploadCatalog"
          accept=".xlsx"
          :hidden="true"
          plain
        />
      </div>
      <div>
        <div class="text-center mt-1">
          <b-button
            type="reset"
            class="mt-2 mr-2 px-sm-3 font-weight-bolder"
            variant="outline-info"
            @click="closeImportDirectRetailersModal"
          >
            Cancel
          </b-button>
          <b-button
            type="submit"
            variant="info"
            class="mt-2 px-sm-3 font-weight-bolder"
            :disabled="isUploadingDirectRetailers || (!directRetailersImportFileLink && !directRetailersImportFile)"
            @click="uploadDirectRetailers"
          >
            {{ isUploadingDirectRetailers ? "Saving": "Save" }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FacebookIcon from '@/@core/assets/svg-components/FacebookIcon.vue'
import ImportIcon from '@/@core/assets/svg-components/ImportIcon.vue'
import InstagramIcon from '@/@core/assets/svg-components/InstagramIcon.vue'
import LinkedInIcon from '@/@core/assets/svg-components/LinkedInIcon.vue'
import PencilIcon2 from '@/@core/assets/svg-components/PencilIcon2.vue'
import SendIcon from '@/@core/assets/svg-components/SendIcon.vue'
import TwitterIcon from '@/@core/assets/svg-components/TwitterIcon.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { apiToastErrorV2, apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import { formatNumber } from '@/@core/utils/utils'
import connectionIcon from '@/assets/images/icons/connectionsIcon.svg'
import { getDateFromTimestamp, getLinkWithhttp } from '@/common-utils'
import constants, { CONNECTION_TABS, FILE_FORMATS } from '@/constants'
import { DOWNLOAD_FILE, UPLOAD_DOCUMENTS } from '@/store/modules/common.module'
import {
GET_MARKETPLACE_DIRECT_USERS,
IMPORT_DIRECT_USERS,
UPDATE_CONNECTION_REQUEST,
} from '@/store/modules/connections.module'
import { GET_DIRECT_CONNECTIONS_TABLE_COLUMNS, GET_MARKETPLACE_CONNECTIONS_TABLE_COLUMNS, salesRepField } from '@/table-columns-constants'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'

import {
BButton,
BCard,
BCardText,
BCol,
BFormFile,
BFormInput,
BImg,
BLink,
BModal,
BPagination,
BRow,
BSpinner,
BTable,
VBModal,
VBTooltip,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import { getSelfOption } from '@/service/user.service'
import { LOAD_PRICE_BOOKS } from '@/store/modules/collection.module'
import UserRoleMixinVue from '../UserRoleMixin.vue'
import AddUserModal from './AddUserModal.vue'
import ConnectionRequest from './ConnectionRequest.vue'
import EditUserModal from './EditUserModal.vue'
import AddNickName from './AddNickName.vue'
import RepsListDropdown from './RepsListDropdown.vue'

const { convertValueToPercent } = utils

const { FILE_TYPES, ROLES } = c

const { CONNECTION_REQUEST_STATUS } = constants
export default {
  name: 'ConnectionRequestList',
  components: {
    BTable,
    BCard,
    FeatherIcon,
    BModal,
    BRow,
    BCol,
    BPagination,
    BSpinner,
    BButton,
    ConnectionRequest,
    AddUserModal,
    BLink,
    InstagramIcon,
    FacebookIcon,
    LinkedInIcon,
    TwitterIcon,
    SendIcon,
    BCardText,
    PencilIcon2,
    EditUserModal,
    BFormInput,
    ImportIcon,
    BImg,
    BFormFile,
    AddNickName,
    RepsListDropdown,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  mixins: [UserRoleMixinVue],
  data() {
    return {
      connectionIcon,
      tableFields: [],
      tableItems: [],
      loading: true,
      brandId: null,
      retailerId: null,
      requestId: null,
      status: null,
      requestMessage: null,
      currentPage: 1,
      perPage: 10,
      tableLength: 0,
      tableDataCount: 0,
      getDateFromTimestamp,
      CONNECTION_REQUEST_STATUS,
      CONNECTION_TABS,
      selectedTab: CONNECTION_TABS.DIRECT,
      connectionData: {},
      userData: null,
      convertValueToPercent,
      search: '',
      filterIncludedFields: ['firstName', 'entityName'],
      isExporting: false,
      directRetailersImportFile: null,
      directRetailersImportFileLink: null,
      isUploadingDirectRetailers: false,
      formatNumber,
      xlsxIcon: require('@/assets/images/icons/xlsxIcon.svg'),
      rawItems: [],
      selectedRepsNames: [],
      selectedSalesManagerNames: [],
      modalReloadKey: 0,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profileData,
      salesReps: state => state.auth.salesReps,
      salesManagers: state => state.auth.salesManagers,
    }),
    getTableFields() {
      const nickNameKey = 'nickName'
      if (this.selectedTab === CONNECTION_TABS.DIRECT) {
        if (this.isRetailer) {
          const retailerExcludedFields = [
            'discount',
            'actions',
            nickNameKey,
          ]
          return GET_DIRECT_CONNECTIONS_TABLE_COLUMNS().filter(
            item => !retailerExcludedFields.includes(item.key),
          )
        } else {
          const brandExcludedFields = [
            'logisticsTerms',
          ]
          const dColumns = GET_DIRECT_CONNECTIONS_TABLE_COLUMNS()
          if ((this.isEntityAdmin || this.isSalesManager) && this.isBrand) {
            dColumns.unshift(salesRepField)
          }
          return dColumns.filter(
            item => !brandExcludedFields.includes(item.key),
          )
        }
      }
      const marketPlaceExcludedFields = this.isRetailer ? [
        nickNameKey,
      ] : []
      const mpColumns = GET_MARKETPLACE_CONNECTIONS_TABLE_COLUMNS()
      return mpColumns.filter(
            item => !marketPlaceExcludedFields.includes(item.key),
      )
    },
    getRoleText() {
      if (this.isBrand) {
        return ROLES.RETAILER
      }
      return ROLES.BRAND
    },
    getTextForPlaceholder() {
      return `Search by Name or ${this.isRetailer ? 'Brand' : 'Store'} Name`
    },
    isShowImportExport() {
      return this.isBrand && this.selectedTab === CONNECTION_TABS.DIRECT
    },
    hasDirectUsers() {
      return this.selectedTab === CONNECTION_TABS.DIRECT && this.tableItems?.length > 0
    },
  },
  watch: {
    role() {
      this.onLoadTab()
    },
  },
  created() {
    this.getData()
    this.$store.dispatch(LOAD_PRICE_BOOKS)
  },
  methods: {
    onFilterBySalesRep(selectedValues = []) {
      this.selectedRepsNames = selectedValues.map(s => s.name)
      this.updateFilter()
    },
    onFilterBySalesManager(selectedValues = []) {
      this.selectedSalesManagerNames = selectedValues.map(s => s.name)
      this.updateFilter()
    },
    updateFilter() {
      const selectedNames = [...this.selectedRepsNames, ...this.selectedSalesManagerNames]
      const isSelectedSelf = selectedNames.includes(getSelfOption().name)
      if (selectedNames.length) {
        this.tableItems = this.rawItems.filter(item => selectedNames.includes(item[salesRepField.key]) || (isSelectedSelf && item.isCreatedBySelf))
        this.tableDataCount = this.tableItems.length
      } else {
        this.tableItems = this.rawItems
        this.tableDataCount = this.tableItems.length
      }
    },
    onLoadTab(tab = '') {
      if (tab) {
        this.selectedTab = tab
      }
      this.search = ''
      this.tableFields = this.getTableFields
      let items = []
      if (this.selectedTab === CONNECTION_TABS.MARKETPLACE) {
        items = this.isBrand
          ? this.connectionData.marketplaceRetailers
          : this.connectionData.marketplaceBrands
      } else {
        items = this.isBrand
          ? this.connectionData.directRetailers
          : this.connectionData.directBrands
      }
      this.tableItems = items?.map(item => ({
        ...item,
        socialLinks: {
          websiteLink: getLinkWithhttp(item?.websiteLink) || '',
          instagramLink: getLinkWithhttp(item?.instagramLink) || '',
          facebookLink: getLinkWithhttp(item?.facebookLink) || '',
          twitterLink: getLinkWithhttp(item?.twitterLink) || '',
          linkedinLink: getLinkWithhttp(item?.linkedinLink) || '',
        },
      }))
      this.tableLength = items?.length
      this.tableDataCount = this.tableItems.length
      this.rawItems = this.tableItems
    },
    async getData(isLive = false) {
      if (!isLive) {
        this.loading = true
        this.tableItems = []
      }
      try {
        const res = await this.$store.dispatch(GET_MARKETPLACE_DIRECT_USERS)
        const data = res?.data?.data
        this.connectionData = data
        this.onLoadTab()
        this.loading = false
      } catch (err) {
        this.loading = false
        console.error(err)
      }
    },
    closeModal() {
      this.$refs.connnectionRequest.hide()
    },
    openConnectionRequest(data) {
      this.brandId = this.tableItems[data.index].brandId
      this.retailerId = this.tableItems[data.index].retailerId
      this.requestId = this.tableItems[data.index].requestId
      this.status = this.tableItems[data.index].status
      this.requestMessage = this.tableItems[data.index].requestMessage
      this.$refs.connnectionRequest.show()
    },
    onUpdateRequest(status, message, requestId) {
      const toastMessage = `Request ${status}`
      const payload = {
        status,
        message,
      }
      this.$store
        .dispatch(UPDATE_CONNECTION_REQUEST, { requestId, payload })
        .then(() => {
          apiToastSuccess(toastMessage)
          this.getData()
        })
        .catch(err => {
          apiToastWarning(err)
        })
    },
    editUser(data) {
      this.userData = data
      this.modalReloadKey += 1
    },
    getDicountWithPercent(discount = 1) {
      if (discount > 1) {
        return 100 + convertValueToPercent(discount)
      }
      return convertValueToPercent(discount)
    },
    exportDirectUsers() {
      this.isExporting = true
      const userName = this.profile?.firstName || ''
      const payload = {
          url: this.$kpEndpoint.user.connection.export,
          fileName: `direct-retailers${ userName ? `-of-${userName}` : ''}.xlsx`,
          fileFormat: FILE_FORMATS.XLSX,
          successMessage: 'File exported successfully',
        }
        this.$store.dispatch(DOWNLOAD_FILE, payload)
        .catch(err => {
          apiToastErrorV2(err)
        })
        .finally(() => {
          this.isExporting = false
        })
    },
    closeImportDirectRetailersModal() {
      this.directRetailersImportFile = null
      this.directRetailersImportFileLink = null
      this.isUploadingDirectRetailers = false
      this.$bvModal.hide('import_direct_retailers')
    },
    async uploadDirectRetailers() {
      try {
        this.isUploadingDirectRetailers = true
        if (!this.directRetailersImportFile) {
          throw new Error('Please select a file')
        }
        const params = {
          shouldUpdateExistingUser: true,
        }
        const storedFile = await this.$store.dispatch(UPLOAD_DOCUMENTS, { file: this.directRetailersImportFile, fileType: FILE_TYPES.DIRECT_RETAILERS })
        const payload = { file: storedFile }
        const result = await this.$store.dispatch(IMPORT_DIRECT_USERS, { payload, params })
        apiToastSuccess(result.data.message)
        this.closeImportDirectRetailersModal()
        this.getData()
      } catch (err) {
        apiToastWarning(err || 'An error occurred while importing direct retailers')
      } finally {
        this.isUploadingDirectRetailers = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/page-misc.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';

.my-retailer-banner-1 {
  background-image: url(./my-retailer-banner1.png);
}
.my-retailer-banner-2 {
  background-image: url(./my-retailer-banner2.png);
}

.my-retailer-banner {
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: cover;
  min-height: 160px;
  border: 1px solid $dashboard-empty-icon-color;
  border-radius: 6px;

  .banner-content {
    margin: 24px;
    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }

    .sub-title {
      margin-top: 5px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.payment-term-block {
  min-width: 200px;
}
.wallet-search-icon {
  position: absolute;
  right: 25px;
  top: 10px;
}

</style>
