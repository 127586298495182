<template>
  <b-modal
    :id="editUserModalId"
    modal-class="modal-add-user modal-edit-user"
    header-bg-variant="white"
    centered
    header-class="d-flex align-items-center"
    hide-footer
    size="md"
  >
    <div
      v-if="userData"
      class="modal-add-user-body mb-2"
    >
      <div class="modal-add-user-header">
        Edit retailer
      </div>
      <div class="mt-2">
        <validation-observer
          ref="updateWalletRuleForm"
          #default="{ invalid }"
        >
          <b-form
            class="mt-2"
            @submit.prevent="updateWalletRule"
          >
            <div>
              <validation-provider
                #default="{ errors }"
                name="Payment Terms"
                rules="required"
              >
                <b-form-group
                  label="Payment Terms"
                  label-class="pb-50"
                >
                  <v-select
                    v-model="userData.paymentTerms"
                    :options="Object.values(PAYMENT_TERMS).map((_) => _.TEXT)"
                    placeholder="Payment Terms"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Logistics  Terms"
                rules="required"
              >
                <b-form-group
                  label="Logistics Terms"
                  label-class="pb-50"
                >
                  <v-select
                    v-model="userData.logisticsTerms"
                    :options="Object.values(LOGISTICS_TERMS)"
                    placeholder="Logistics Terms"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <b-form-group
                label="Price Books"
                label-for="price_books"
                label-class="pb-50"
              >
                <b-dropdown
                  id="price_books"
                  v-click-outside="hidePriceBooksDropdown"
                  block
                  variant="none"
                  class="price-books-dropdown"
                  :class="{'show' :showPriceBooks}"
                  :menu-class="`w-100 ${showPriceBooks ? 'show' : ''}`"
                  toggle-class="p-0"
                  toggle-tag="div"
                  dropdown
                  no-caret
                  @show.prevent
                  @hide.prevent
                >
                  <template #button-content>
                    <div class="d-flex align-items-center justify-content-between">
                      <v-select
                        v-model="selectedPriceBooks"
                        :options="priceBooks"
                        multiple
                        :close-on-select="false"
                        :deselect-from-dropdown="true"
                        :no-drop="true"
                        :searchable="false"
                        :clearable="true"
                        class="w-100 price-book-v-select"
                        placeholder="Select price books"
                        label="priceBookName"
                        @search:focus="showPriceBooks=true"
                      />
                      <feather-icon
                        :icon="showPriceBooks ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                        class="wishlist-caret-icon m-5-px"
                        size="16"
                        @click="showPriceBooks=!showPriceBooks"
                      />
                    </div>

                  </template>
                  <template v-if="!collectionWithPriceBooks.length">
                    <b-dropdown-item
                      disabled
                      class="text-center px-50"
                    >
                      {{ priceBooksEmptyMessage }}
                    </b-dropdown-item>
                  </template>
                  <template v-else>
                    <b-dropdown-text>
                      <b-form-input
                        placeholder="Search price books"
                        @input="onSearchPriceBooks"
                      />
                    </b-dropdown-text>
                    <template v-if="isPriceBooksEmpty">
                      <b-dropdown-item
                        disabled
                        class="text-center px-50"
                      >
                        {{ priceBooksEmptyMessage }}
                      </b-dropdown-item>
                    </template>
                    <template v-else>
                      <template v-for="collection in collectionWithPriceBooks">
                        <div
                          v-if="collection.priceBooks.length"
                          :key="collection.collectionId"
                        >
                          <b-dropdown-header class="collection-name-label">
                            Collection: {{ collection.collectionName || "" }}
                          </b-dropdown-header>
                          <b-dropdown-item
                            v-for="priceBook in collection.priceBooks"
                            :key="`pb_${priceBook._id}`"
                            :active="selectedPriceBookIds.includes(priceBook._id)"
                            @click="handleSelectPriceBook(priceBook)"
                          >
                            <div class="w-100 d-flex justify-content-between align-items-center pl-2">
                              <div class="text-wrap">
                                {{ priceBook.priceBookName || "" }}
                              </div>
                              <div v-if="selectedPriceBookIds.includes(priceBook._id)">
                                <feather-icon
                                  icon="CheckIcon"
                                  size="16"
                                />
                              </div>
                            </div>
                          </b-dropdown-item>
                        </div>
                      </template>
                    </template>

                  </template>
                </b-dropdown>
              </b-form-group>

              <b-form-group
                label="Currency"
                label-class="pb-50"
                class="currency-list-dropdown"
              >
                <v-select
                  v-model="userData.availableCurrencies"
                  :options="availableCurrencies"
                  :close-on-select="false"
                  multiple
                  placeholder="Select currencies"
                />
              </b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Discount"
                rules="required|between:0,100"
              >
                <b-form-group
                  label="Discount(%)"
                  label-class="pb-50"
                >
                  <b-form-input
                    v-model="discount"
                    placeholder="Discount"
                    type="number"
                    step="any"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <div class="font-italic">
                <div>
                  <span> Ex: If your wholesale price is </span>
                </div>
                <div class="d-flex flex-row align-items-center">
                  <b-form-input
                    v-model="exampleWholeSalePrice"
                    type="number"
                    size="sm"
                    class="custom-ws-price"
                    :disabled="invalid"
                  />
                  <span> , then the updated price </span>
                </div>
                <div>
                  for this retailer is
                  <span>
                    {{ getUpdatedPriceForRetailer }}
                  </span>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex justify-content-end mt-2 mb-1">
              <b-button
                variant="outline-primary"
                @click="$bvModal.hide(editUserModalId)"
              >
                <span> Cancel </span>
              </b-button>
              <b-button
                class="ml-1"
                type="submit"
                variant="custom-primary"
                :disabled="invalid || isEditing"
              >
                <span> Save </span>
                <feather-icon
                  v-if="isEditing"
                  class="ml-1"
                  icon="LoaderIcon"
                />
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownHeader,
  BDropdownItem,
  BDropdownText,
  BForm,
  BFormGroup,
  BFormInput,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { UPDATE_WALLET_RULE } from '@/store/modules/wallet.module'

import { mapState } from 'vuex'
import { loadOptionsCollectionsWithPriceBooks } from './wallet-rules.utils'

const {
 calculateCommercialTerms,
  convertPercentToFraction,
  convertValueToPercent,
  round,
} = utils

const {
 PAYMENT_TERMS,
  LOGISTICS_TERMS,
  CURRENCY_CONVERSIONS,
} = c

const availableCurrencies = Object.keys(CURRENCY_CONVERSIONS)

export default {
  name: 'AddUserModal',
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    FeatherIcon,
    BDropdown,
    BDropdownHeader,
    BDropdownItem,
    BDropdownText,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    userDetails: {
      type: Object,
      default: () => null,
    },
    loadConnections: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      discount: '',
      isEditing: false,
      PAYMENT_TERMS,
      LOGISTICS_TERMS,
      exampleWholeSalePrice: null,
      collectionWithPriceBooksRawData: [],
      collectionWithPriceBooks: [],
      selectedPriceBooks: [],
      availableCurrencies,
      showPriceBooks: false,
      priceBooksEmptyMessage: 'No price books found',
      userData: null,
      editUserModalId: 'edit_user_modal_id',
    }
  },
  computed: {
    ...mapState({
      priceBooks: state => state.collection.priceBooks || [],
    }),
    getUpdatedPriceForRetailer() {
      let convertedDiscount = 0
      if (this.discount > 100) {
        convertedDiscount = convertPercentToFraction(this.discount)
      } else {
        convertedDiscount = calculateCommercialTerms(this.discount)
      }
      return round(this.exampleWholeSalePrice * convertedDiscount)
    },
    selectedPriceBookIds() {
      return this.selectedPriceBooks.map(book => book._id) || []
    },
    isPriceBooksEmpty() {
      return this.collectionWithPriceBooks.every(collection => !collection.priceBooks.length)
    },
  },
  watch: {
    discount: {
      async handler() {
        const isValidForm = await this.$refs?.updateWalletRuleForm?.validate()
        if (!isValidForm) {
          this.exampleWholeSalePrice = null
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.userData = this.userDetails
    this.setPriceBooks()
    this.updateDiscount()
    // show modal
    this.$bvModal.show(this.editUserModalId)
  },
  methods: {
    loadSelectedPriceBooks() {
      this.selectedPriceBooks = this.priceBooks.filter(
        book => this.userData?.priceBookIds?.includes(book._id),
      )
    },
    hidePriceBooksDropdown() {
      this.showPriceBooks = false
    },
    onSearchPriceBooks(search = '') {
      if (search) {
        this.collectionWithPriceBooks = this.collectionWithPriceBooksRawData.map(collection => ({
            ...collection,
            priceBooks: collection.priceBooks.filter(book => book.priceBookName.toLowerCase().includes(search.toLowerCase())),
          }))
          return
      }
      this.collectionWithPriceBooks = loadOptionsCollectionsWithPriceBooks(this.priceBooks)
    },
    deselectPriceBookById(priceBook) {
      this.selectedPriceBooks = this.selectedPriceBooks.filter(book => book._id !== priceBook._id)
    },
    handleSelectPriceBook(priceBook) {
      if (this.selectedPriceBookIds.includes(priceBook._id)) {
        this.deselectPriceBookById(priceBook)
        return
      }
      this.selectedPriceBooks.push(priceBook)
    },
    async setPriceBooks() {
        this.collectionWithPriceBooks = loadOptionsCollectionsWithPriceBooks(this.priceBooks)
        this.collectionWithPriceBooksRawData = this.collectionWithPriceBooks
        this.loadSelectedPriceBooks()
    },
    async updateWalletRule() {
      try {
        this.isEditing = true
        const payload = {
          paymentTerms: this.userData.paymentTerms,
          logisticsTerms: this.userData.logisticsTerms,
          availableCurrencies: this.userData.availableCurrencies || [],
          value:
            this.discount > 100
              ? convertPercentToFraction(this.discount)
              : calculateCommercialTerms(this.discount),
        }
        payload.priceBookIds = this.selectedPriceBookIds
        await this.$store.dispatch(UPDATE_WALLET_RULE, {
          walletRuleId: this.userData.walletRuleId,
          payload,
        })
        apiToastSuccess('Updated successfully')
        await this.loadConnections(true)
        this.isEditing = false
        this.$bvModal.hide(this.editUserModalId)
      } catch (err) {
        this.isEditing = false
        apiToastError(err)
      }
    },
    updateDiscount() {
      this.discount = convertValueToPercent(this.userData.discount)
      if (this.userData.discount > 1) {
        this.discount += 100
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include';

.vs__search {
  &::placeholder {
    color: $text-muted !important;
  }
}

.custom-ws-price {
  width: 45px;
  border: none;
  border-bottom: 1px solid $black;
  border-radius: 0%;
  &:focus {
    box-shadow: none !important;
  }
}

.currency-list-dropdown, .price-books-dropdown {
  .vs__selected-options {
    .vs__selected {
      background: #F3F3F3;
      color: $primary;
      text-align: start;
    }
  }
}

.price-books-dropdown {
  border: 1px solid $custom-control-border-color;
  border-radius: 0.357rem;

  &.show {
    border-color: $primary;
  }

  .dropdown-menu {
    max-height: 300px !important;
    overflow-x: auto;
    margin-top: 10px;

    .collection-name-label {
      header {
        color: $primary !important;
        font-weight: 1000 !important;
      }
      background-color: $gray-600 !important;
    }
      .dropdown-item {
        background: $white;
      }

     .dropdown-item:hover {
        background: $gray-50;
      }

      .dropdown-item.active, .dropdown-item:active {
        background: $primary;
        color: $white;
      }
  }
}

.price-book-v-select {
  .vs__dropdown-toggle {
    border: none;
  }
}
</style>
