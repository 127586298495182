<template>
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.33328 10.6287L9.09872 7.17195L7.02464 7.17195L7.02464 0.949707L5.64192 0.949707L5.64192 7.17195H3.56784L6.33328 10.6287Z"
      fill="currentColor"
    />
    <path
      d="M11.1727 4.9624H8.40728V6.34512H11.1727V12.5674H1.49368V6.34512H4.25912V4.9624H1.49368C0.731112 4.9624 0.110962 5.58255 0.110962 6.34512V12.5674C0.110962 13.3299 0.731112 13.9501 1.49368 13.9501H11.1727C11.9353 13.9501 12.5554 13.3299 12.5554 12.5674V6.34512C12.5554 5.58255 11.9353 4.9624 11.1727 4.9624Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'ImportIcon',
}
</script>
