<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6666 3.94957C14.1763 4.16684 13.6495 4.31365 13.0958 4.38C13.6607 4.04176 14.0941 3.50505 14.2984 2.86616C13.7699 3.17973 13.1845 3.40757 12.5614 3.52971C12.0623 2.99887 11.3518 2.6665 10.5649 2.6665C9.05456 2.6665 7.82963 3.89144 7.82963 5.40293C7.82963 5.61668 7.85429 5.82455 7.90009 6.02538C5.6258 5.91087 3.61106 4.82159 2.26164 3.16681C2.02675 3.57199 1.89169 4.04176 1.89169 4.54207C1.89169 5.49101 2.37497 6.32897 3.10841 6.8193C2.65977 6.80462 2.23815 6.68189 1.86938 6.47695V6.51218C1.86938 7.83753 2.81186 8.94326 4.06381 9.19459C3.83362 9.25683 3.59227 9.28972 3.34329 9.28972C3.16713 9.28972 2.99507 9.27327 2.8283 9.24157C3.17652 10.3279 4.18654 11.1195 5.38387 11.1406C4.44726 11.8746 3.26754 12.3121 1.98624 12.3121C1.76544 12.3121 1.54759 12.2992 1.33325 12.274C2.54409 13.0508 3.9816 13.503 5.52598 13.503C10.5584 13.503 13.3095 9.33493 13.3095 5.72003C13.3095 5.60259 13.3066 5.48397 13.3013 5.36652C13.8357 4.98014 14.2996 4.49921 14.6654 3.95133L14.6666 3.94957Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'TwitterIcon',
}
</script>
