<template>
  <div>
    <!-- nick name cell -->
    <div
      v-if="getNickName"
      class="d-flex flex-nowrap align-items-center justify-content-between"
    >
      <div>{{ data.item.retailerBrandIdentity }}</div>
      <div
        class="cursor-pointer"
        @click="addOrEditNickName()"
      >
        <feather-icon
          icon="EditIcon"
          size="15"
          class="edit-icon"
        />
      </div>
    </div>
    <div
      v-else
      class="elements-center"
    >
      <span
        class="cursor-pointer"
        @click="addOrEditNickName()"
      >
        <pencil-icon-2 />
      </span>
    </div>

    <!-- add or edit nick name modal -->
    <b-modal
      :id="modalId"
      hide-header
      hide-header-close
      hide-footer
      no-close-on-backdrop
      centered
    >
      <div class="pb-5-px border-bottom">
        <span
          class="custom-modal-heading color-dark-black"
        >{{ getNickName ? 'Update' : 'Enter' }} nickname</span>
        <feather-icon
          class="float-right close-x-icon"
          icon="XIcon"
          size="24"
          @click="onClickCancel"
        />
      </div>
      <div class="mt-1">
        <validation-observer
          ref="addNickNameForm"
          #default="{ invalid }"
        >
          <b-form
            class="mt-2"
            @submit.prevent="onSubmitNickName()"
          >
            <div>
              <validation-provider
                #default="{ errors }"
                name="Nickname"
                rules="required|min:3"
              >
                <b-form-group
                  label="Nickname"
                  label-class="pb-0"
                >
                  <b-form-input
                    v-model="nickName"
                    placeholder="Nickname"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="text-center my-1">
              <b-button
                type="submit"
                variant="info"
                class="mt-1 mr-2 px-sm-3 font-weight-bolder"
                :disabled="invalid || saving"
              >
                {{ saving ? 'Saving' : 'Save' }}
              </b-button>
              <b-button
                type="reset"
                class="mt-1 px-sm-3 font-weight-bolder"
                variant="outline-info"
                @click="onClickCancel"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import PencilIcon2 from '@/@core/assets/svg-components/PencilIcon2.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import {
 BButton, BForm, BFormGroup, BFormInput, BModal,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import {
  ADD_NICK_NAME,
  UPDATE_NICK_NAME,
} from '../../../store/modules/auth.module'

export default {
  name: 'AddNickName',
  components: {
    PencilIcon2,
    BModal,
    BButton,
    FeatherIcon,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      saving: false,
      nickName: '',
    }
  },
  computed: {
    getNickName() {
      return this.data?.item?.retailerBrandIdentity
    },
    modalId() {
      return `add_or_edit_nick_name_${this.data.item.retailerId}`
    },
  },
  watch: {
    getNickName: {
      handler() {
        this.nickName = this.buildDefaultValues()
      },
      immediate: true,
    },
  },
  emits: ['load-connections'],
  methods: {
    buildDefaultValues() {
      return this.getNickName || ''
    },
    addOrEditNickName() {
      this.$bvModal.show(this.modalId)
    },
    onClickCancel() {
      this.$bvModal.hide(this.modalId)
      this.nickName = this.buildDefaultValues()
    },
    onSubmitNickName() {
      this.saving = true
      const payload = {
        retailerId: this.data.item.retailerId,
        retailerBrandIdentity: this.nickName,
      }
      const endpoint = this.getNickName ? UPDATE_NICK_NAME : ADD_NICK_NAME
      this.$store
        .dispatch(endpoint, { payload })
        .then(res => {
          this.$bvModal.hide(this.modalId)
          this.nickName = this.buildDefaultValues()
          this.saving = false
          apiToastSuccess(res.data.message)
          this.$emit('load-connections')
        })
        .catch(err => {
          apiToastError(err)
          this.saving = false
        })
    },
  },
}
</script>
