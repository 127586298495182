<template>
  <div>
    <div class="custom-modal-header">
      <feather-icon
        class="chevron-left-icon"
        icon="ChevronLeftIcon"
        size="24"
        @click="$emit('close-modal')"
      />
      <span class="heading ">Connection Request</span>
      <feather-icon
        class="close-x-icon"
        icon="XIcon"
        size="24"
        @click="$emit('close-modal')"
      />
    </div>
    <div
      v-if="loading"
      class="app-connection-request"
    >
      <b-card-text>
        <span v-if="isRetailer">Request access to shop from this brand: </span>
        <span v-else> You have received a new connection request: </span>
      </b-card-text>
      <div class="profile-data">
        <div class="profile">
          <b-card-text class="d-flex align-items-center">
            <span v-if="isRetailer">Your info:
              <b-img
                id="profile-tooltip"
                :src="tooltipicon"
                alt="tooltipicon"
              />
              <b-tooltip
                target="profile-tooltip"
                placement="bottom"
              >
                <span class="tooltip-content">Adding your Social Links may increase chances of receiving a quick approval to your connection. Update your Profile
                  <b-link
                    class="profile-link"
                    :to="{name: 'profile'}"
                    target="_blank"
                  >here. </b-link>
                </span>
              </b-tooltip>
            </span>
            <span v-else> Retailer info: </span>
          </b-card-text>
          <div class="d-flex align-items-center row m-0">
            <div class="col-3 p-0">
              <b-img
                class="entity-image"
                :src="retailerData.logo ? retailerData.logo:defaultImage"
                alt="retailerlogo"
              />
            </div>
            <div class="d-flex flex-column col-9 pl-2">
              <b-card-text class="entity-name">
                {{ retailerData.entityName }}
              </b-card-text>
              <b-card-text class="entity-country">
                {{ retailerData.country }}
              </b-card-text>
              <div class="d-flex flex-coumn pr-2">
                <b-link
                  class="link"
                  :href="retailerData.websiteLink ? websiteLink(retailerData.websiteLink):''"
                  target="_blank"
                >
                  <b-img
                    :src="globeIcon"
                    alt="globeIcon"
                    :class="!retailerData.websiteLink ? 'disabled-icon':''"
                  />
                </b-link>
                <b-link
                  class="link"
                  :href="retailerData.linkedinLink ? websiteLink(retailerData.linkedinLink):''"
                  target="_blank"
                >
                  <b-img
                    :src="linkedinIcon"
                    alt="linkedinIcon"
                    :class="!retailerData.linkedinLink ? 'disabled-icon':''"
                  />
                </b-link>
                <b-link
                  class="link"
                  :href="retailerData.facebookLink ? websiteLink(retailerData.facebookLink):''"
                  target="_blank"
                >
                  <b-img
                    :src="facebookIcon"
                    alt="facebookIcon"
                    :class="!retailerData.facebookLink ? 'disabled-icon':''"
                  />
                </b-link>
                <b-link
                  class="link"
                  :href="retailerData.instagramLink ? websiteLink(retailerData.instagramLink):''"
                  target="_blank"
                >
                  <b-img
                    :src="instagramIcon"
                    alt="instagramIcon"
                    :class="!retailerData.instagramLink ? 'disabled-icon':''"
                  />
                </b-link>
                <b-link
                  class="link"
                  :href="retailerData.twitterLink ? websiteLink(retailerData.twitterLink):''"
                  target="_blank"
                >
                  <b-img
                    :src="twitterIcon"
                    alt="twitterIcon"
                    :class="!retailerData.twitterLink ? 'disabled-icon':''"
                  />
                </b-link>
              </div>
            </div>
          </div>
        </div>
        <div class="arrow-icon">
          <b-img
            :src="rightArrowIcon"
            alt="rightArrowIcon"
          />
        </div>
        <div class="profile">
          <b-card-text class="d-flex align-items-center">
            <span v-if="isRetailer">Requesting access to: </span>
            <span v-else> Your info: </span>
          </b-card-text>
          <div class="d-flex align-items-center row m-0">
            <div class="col-3 p-0">
              <b-img
                class="entity-image"
                :src="brandData.logo ? brandData.logo:defaultImage"
                alt="brandlogo"
              />
            </div>
            <div class="d-flex flex-column col-9 pl-2">
              <b-card-text class="entity-name">
                {{ brandData.entityName }}
              </b-card-text>
              <b-card-text class="entity-country">
                {{ brandData.country }}
              </b-card-text>
              <div class="d-flex flex-coumn pr-2">
                <b-link
                  class="link"
                  :href="brandData.websiteLink ? websiteLink(brandData.websiteLink):''"
                  target="_blank"
                >
                  <b-img
                    :src="globeIcon"
                    alt="globeIcon"
                    :class="!brandData.websiteLink ? 'disabled-icon':''"
                  />
                </b-link>
                <b-link
                  class="link"
                  :href="brandData.linkedinLink ? websiteLink(brandData.linkedinLink):''"
                  target="_blank"
                >
                  <b-img
                    :src="linkedinIcon"
                    alt="linkedinIcon"
                    :class="!brandData.linkedinLink ? 'disabled-icon':''"
                  />
                </b-link>
                <b-link
                  class="link"
                  :href="brandData.facebookLink ? websiteLink(brandData.facebookLink):''"
                  target="_blank"
                >
                  <b-img
                    :src="facebookIcon"
                    alt="facebookIcon"
                    :class="!brandData.facebookLink ? 'disabled-icon':''"
                  />
                </b-link>
                <b-link
                  class="link"
                  :href="brandData.instagramLink ? websiteLink(brandData.instagramLink):''"
                  target="_blank"
                >
                  <b-img
                    :src="instagramIcon"
                    alt="instagramIcon"
                    :class="!brandData.instagramLink ? 'disabled-icon':''"
                  />
                </b-link>
                <b-link
                  class="link"
                  :href="brandData.twitterLink ? websiteLink(brandData.twitterLink):''"
                  target="_blank"
                >
                  <b-img
                    :src="twitterIcon"
                    alt="twitterIcon"
                    :class="!brandData.twitterLink ? 'disabled-icon':''"
                  />
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-card-text class="mt-2">
        <span v-if="isRetailer">Message to Brand(Optional):</span>
        <span v-else>Message From Retailer:</span>
      </b-card-text>
      <b-form-textarea
        v-model="message"
        class="entity-message"
        rows="4"
        maxlength="140"
        :disabled="isBrand"
        :placeholder="isRetailer ? 'Intoduce your store and let the brand know why they\'re the perfect fit for you...' : 'No Message'"
      />
      <div
        v-if="isUpdateEnabledRetailer"
        class="d-flex align-items-center justify-content-center my-2"
      >
        <b-button
          variant="outline-info"
          class="btn-custom-radius mr-2"
          @click="$emit('close-modal')"
        >
          Cancel
        </b-button>
        <b-button
          variant="info"
          class="btn-custom-radius"
          @click="onSubmitRequest"
        >
          {{ isUpdateEnabled ? 'Save changes':'Submit request' }}
        </b-button>
      </div>
      <div
        v-if="isBrand"
        class="d-flex align-items-center justify-content-center my-2"
      >
        <b-button
          variant="outline-info"
          class="btn-custom-radius mr-2"
          @click="onUpdateRequest(CONNECTION_REQUEST_STATUS.DECLINED)"
        >
          Reject
        </b-button>
        <b-button
          variant="info"
          class="btn-custom-radius"
          @click="onUpdateRequest(CONNECTION_REQUEST_STATUS.APPROVED)"
        >
          Accept Request
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  VBTooltip,
  BCardText,
  BImg,
  BFormTextarea,
  BButton,
  BLink,
  BTooltip,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import store from '@/store'
import { apiToastError, apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import constants from '@/constants'
import { GET_CONNECTION_INFO, SEND_CENNECTION_REQUEST, UPDATE_CONNECTION_REQUEST } from '@/store/modules/connections.module'

const { CONNECTION_REQUEST_STATUS } = constants
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCardText,
    BImg,
    BFormTextarea,
    FeatherIcon,
    BButton,
    BLink,
    BTooltip,
  },
  props: {
    brandId: {
      type: String,
      default() {
        return null
      },
    },
    retailerId: {
      type: String,
      default() {
        return null
      },
    },
    requestId: {
      type: String,
      default() {
        return null
      },
    },
    status: {
      type: String,
      default() {
        return null
      },
    },
    requestMessage: {
      type: String,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      retailerData: null,
      brandData: null,
      message: '',
      loading: false,
      defaultImage: require('@/assets/images/elements/default-product.jpeg'),
      globeIcon: require('@/assets/images/icons/globeicon.svg'),
      facebookIcon: require('@/assets/images/icons/facebookicon.svg'),
      linkedinIcon: require('@/assets/images/icons/linkedinicon.svg'),
      instagramIcon: require('@/assets/images/icons/instagramicon.svg'),
      twitterIcon: require('@/assets/images/icons/twittericon.svg'),
      rightArrowIcon: require('@/assets/images/icons/rightarrowicon.svg'),
      tooltipicon: require('@/assets/images/icons/tooltipicon1.svg'),
      CONNECTION_REQUEST_STATUS,
    }
  },
  computed: {
    isBrand() {
      return store.getters.isBrand
    },
    isRetailer() {
      return store.getters.isRetailer
    },
    isUpdateEnabled() {
      return store.getters.isRetailer && this.$root.$route.name === 'wallet'
    },
    isButtonEnabled() {
      return this.status === CONNECTION_REQUEST_STATUS.PENDING
    },
    isUpdateEnabledRetailer() {
      return store.getters.isRetailer && (!this.status || this.status === CONNECTION_REQUEST_STATUS.PENDING)
    },
    websiteLink() {
      return link => {
        let httpLink
        if (link.includes('https://') || link.includes('http://')) {
          httpLink = link
        }
        else {
          httpLink = `http://${link}`
        }
        return httpLink
      }
    },
  },
  mounted() {
    this.getData()
  },
  emits: ['close-modal', 'requested', 'update-request'],
  methods: {
    getData() {
      const params = {}
      if (this.isRetailer) { params.brandId = this.brandId }
      else { params.retailerId = this.retailerId }
      this.$store.dispatch(GET_CONNECTION_INFO, params)
        .then(res => {
          this.retailerData = res.data.data.retailerInfo
          this.brandData = res.data.data.brandInfo
          this.message = res.data.data.message || ''
          this.loading = true
        })
        .catch(err => {
          apiToastError(err)
        })
    },
    onSubmitRequest() {
      if (this.isButtonEnabled) {
        this.onUpdateRequest(CONNECTION_REQUEST_STATUS.PENDING)
      }
      else {
        const payload = {
          brandId: this.brandId,
          message: this.message,
        }
        this.$store.dispatch(SEND_CENNECTION_REQUEST, payload)
          .then(() => {
            apiToastSuccess('Connection Request made')
            this.$emit('close-modal')
            this.$emit('request')
          })
          .catch(err => {
            apiToastWarning(err)
          })
      }
    },
    async onUpdateRequest(status) {
      const toastMessage = this.isBrand ? `Request ${status}` : 'Request message updated'
      if (this.isRetailer && this.message === this.requestMessage) {
        apiToastWarning('No changes found')
        return
      }
      try {
        const payload = { status, message: this.message }
        await this.$store.dispatch(UPDATE_CONNECTION_REQUEST, { requestId: this.requestId, payload })
        apiToastSuccess(toastMessage)
        this.$emit('update-request')
        this.$emit('close-modal')
      } catch (err) {
        apiToastWarning(err)
      }
    },
  },
}
</script>

<style lang='scss' >
@import "~@core/scss/base/pages/page-misc.scss";
</style>
