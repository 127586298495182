export const loadOptionsCollectionsWithPriceBooks = (priceBooks = []) => {
    const collectionIds = [...new Set(priceBooks.map(book => book.collectionId))]
    const collectionWithPriceBooks = collectionIds.map(collectionId => {
      const collection = priceBooks.find(book => book.collectionId === collectionId)
      return {
        collectionId,
        collectionName: collection.collectionName,
        priceBooks: priceBooks.filter(book => book.collectionId === collectionId),
      }
    })
    return collectionWithPriceBooks
}
